<template>
	<div>
		<template v-if="isNoEmpty"></template>
		<div >
			
			<div v-if="!showmoban&&smallitem.length">
				<div :class="$style.box"  >
					<div class="w100 " @click="gobiglist" >
						<img class="w100 h-180" v-if="smallitem.length" :src="smallitem[0].imageurl" alt="">
					</div>
				</div>
			</div>
			<div :class="$style.box" v-else >
				<div :class="$style.emptyBox">
					<img class="w100 h-180"  src="http://dev.wxkingbos.com/group1/M00/04/8B/rBAAA2Pdy8mAb_-nAAP5b8ZyGW0952.png" alt="">
				</div>
			</div>
		</div>
	</div>
 
</template>

<script>
export default {
    name: 'TextView',
	data() {
		return{
			smallitem:[],
			showmoban:false
		}
	},
    props: {
        formData: {
            type: Object,
            default: () => ({})
        }
    },
	watch:{
		formData:function(e){
			this.formData=e
			console.log('formData',e)
		}
	},
	computed: {
		isNoEmpty() {
			const {formData} = this;
			console.log('formData',formData)
			this.getselectSpecialByPid()
			console.log('this.$router',)
			if(this.$route.path=='/edit'){
				this.showmoban=true
			}
			// this.showmoban=formData.imgList && formData.imgList.length > 0 && formData.imgList[0].imgUrl
		}
	},
	methods:{
		
		getselectSpecialByPid(){
			const {formData} = this;
			let smallitem=formData.smallitem
			this.requset('/b2bgoods/selectSpecialByPid',{typeid:1}).then((resp)=> {
				if(resp.data.length){
					resp.data.map(res=>{
						if(res.id==formData.SelectWidget)smallitem[0]=res
					})
					this.smallitem=smallitem
				}else{
					this.smallitem=[]
				}
					
			});
		
		},
		
		gobiglist(){
			console.log('formData',this.formData)
			if(location.href.indexOf('edit')==-1){
				document.documentElement.scrollTop=0
				this.$router.push({path:'/bigsite',query:{id:this.formData.SelectWidget}})
			}
			
		}
		
		
	}
};
</script>
<style>
	h2 {
	    text-align: center;
	    font-size: 38px;
	    padding: 30px 10px;
	    font-weight: bold;
	}
</style>
<style module>
    .box {
        height: 180px;
		margin: 20px 0;
    }
	
</style>
